export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_REFRESH = 'AUTH_REFRESH'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_ROLE_ADMIN = 'ADMIN'
export const AUTH_ROLE_USER = 'USER'

import { login, logout, refresh } from '@/api'

export default {
  state: {
    role: undefined,
    username: undefined,
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    status: undefined,
    hasLoadedOnce: false
  },

  actions: {
    [AUTH_REQUEST]: ({ commit }, { username, password, saveCredentials }) => {
      commit(AUTH_REQUEST)

      return login({ username, password }, saveCredentials)
        .then(resp => {
          commit(AUTH_SUCCESS, resp)
          return resp
        })
        .catch(e => {
          commit(AUTH_ERROR)
          throw e
        })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      logout()
      commit(AUTH_LOGOUT)
    },
    [AUTH_REFRESH]: ({ commit }) => {
      commit(AUTH_REQUEST)
      return refresh()
        .then(resp => {
          commit(AUTH_SUCCESS, resp)
          return resp
        })
        .catch(e => {
          commit(AUTH_ERROR)
          throw e
        })
    }
  },

  mutations: {
    [AUTH_REQUEST]: state => {
      state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, { username, role, token, refreshToken }) => {
      state.status = 'success'
      state.username = username
      state.role = role
      state.token = token
      state.refreshToken = refreshToken
      state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: state => {
      state.status = 'error'
      state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: state => {
      state.username = undefined
      state.token = undefined
      state.role = undefined
      state.refreshToken = undefined
      state.hasLoadedOnce = true
    }
  }
}
