<template>
  <el-menu ref="menu" class="c-navigation" mode="horizontal" @select="handleSelect">
    <li class="el-menu-item">
      <span class="logo">
        <img src="../assets/logo.svg" height="25px" />
      </span>
      <span class="title">Banque de données de sous-titrage</span>
    </li>
    <el-menu-item
      v-if="$store.getters.isAdmin && $route.name != 'login'"
      index="subtitles"
    >Sous-titres</el-menu-item>
    <el-menu-item v-if="$store.getters.isAdmin && $route.name != 'login'" index="orders">Commandes</el-menu-item>
    <el-submenu index="1" v-if="$route.name != 'login'" class="menu-user">
      <template slot="title">
        <i class="el-icon-user" />
        <span>{{ $store.getters.username }}</span>
      </template>
      <el-menu-item index="logout">Logout</el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/modules/auth'

export default {
  name: 'Navigation',
  data() {
    return {
      activeIndex: ''
    }
  },
  watch: {
    $route: function(value) {
      this.$refs.menu.activeIndex = value.name
    }
  },
  methods: {
    handleSelect(key) {
      switch (key) {
        case 'logout':
          this.$store.dispatch(AUTH_LOGOUT).then(() => {
            this.$router.replace({ name: 'login' })
          })
          break
        case 'subtitles':
          this.$router.push({ name: 'subtitles' })
          break
        case 'orders':
          this.$router.push({ name: 'orders' })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.c-navigation {
  padding: 0 20px;
  .logo,
  .title {
    margin-left: 20px;
  }

  .menu-user {
    float: right !important;
  }

  .el-submenu__title {
    border-bottom-color: transparent !important;
  }
}
</style>
