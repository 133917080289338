import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/subtitles',
    name: 'subtitles',
    component: () => import(/* webpackChunkName: "subtitles" */ '../views/Subtitles.vue'),
    meta: {
      roles: ['ADMIN', 'USER']
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
    meta: {
      roles: ['ADMIN']
    }
  },
  {
    path: '*',
    name: '404-not-found',
    component: () => import('../views/Login.vue') // TODO: create a 404 Page
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
