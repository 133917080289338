let token = localStorage.getItem('token')
let refreshToken = localStorage.getItem('refreshToken')
if (!refreshToken || refreshToken.length == 0) {
  refreshToken = undefined
}
let expires = 0
let sessionSaveLogin = undefined

let API_BASE_URL = ''
switch (document.location.hostname.toLowerCase()) {
 case '127.0.0.1':
 case 'localhost':
   API_BASE_URL = 'http://localhost:80/'
   break
 default:
   API_BASE_URL = ''
   break
}

export const login = (payload, saveLogin) => {
  const url = API_BASE_URL + 'auth/authenticate'
  sessionSaveLogin = saveLogin

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(res => {
      if (!res.ok) {
        throw new Error('The username and password did not match.')
      } else {
        return res
      }
    })
    .then(res => res.json())
    .then(res => {
      token = res.token
      refreshToken = res.refreshToken

      if (saveLogin) {
        localStorage.setItem('token', res.token)
        localStorage.setItem('refreshToken', res.refreshToken)
      }

      expires = +new Date(res.tokenExpiration)
      return res
    })
    .catch(error => {
      throw error
    })
}

export const logout = () => {
  token = undefined
  refreshToken = undefined
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('token')
  expires = 0
}

export const refresh = () => {
  if (!refreshToken) {
    return Promise.resolve()
  }
  const url = API_BASE_URL + 'auth/refresh'
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(res => res.json())
    .then(res => {
      token = res.token
      refreshToken = res.refreshToken

      if (sessionSaveLogin) {
        localStorage.setItem('token', res.token)
        localStorage.setItem('refreshToken', res.refreshToken)
      }
      expires = +new Date(res.tokenExpiration)
      return res
    })
}

export const request = (url, config = {}, isJson = true) => {
  url = API_BASE_URL + url

  config.headers = {
    ...(config.headers || {}),
    Authorization: 'Bearer ' + token
  }

  if (isJson) {
    config.headers['Content-Type'] = 'application/json'
  }

  if (+new Date() - 5000 > expires) {
    return refresh().then(() => fetch(url, config))
  }

  return fetch(url, config)
}

export const getSubtitles = (searchText, sort = '', page = 1, pageSize = 10) => {
  const url = 'subtitles'

  return request(`${url}?search=${searchText}&sort=${sort}&page=${page}&pageSize=${pageSize}`)
}

export const getOrders = (searchText, sort = '', page = 1, pageSize = 10) => {
  const url = 'orders'

  return request(`${url}?search=${searchText}&sort=${sort}&page=${page}&pageSize=${pageSize}`)
}

export const importFile = payload => {
  const url = 'import'
  return request(
    url,
    {
      method: 'POST',
      body: payload
    },
    false
  )
}

export const orderSubtitle = payload => {
  const url = 'subtitles/order'
  return request(url, {
    method: 'POST',
    body: JSON.stringify(payload)
  })
}

export default request
