import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/fr'
import 'element-ui/lib/theme-chalk/index.css'
import { AUTH_LOGOUT, AUTH_REFRESH } from './store/modules/auth'
Vue.use(ElementUI, { locale })

async function init() {
  try {
    await store.dispatch(AUTH_REFRESH)

    const isRootPath = ['', '/'].includes(document.location.pathname)
    const isDefaultPage = ['', '#/'].includes(document.location.hash)

    const isLoginPage = isRootPath && isDefaultPage
    if (isLoginPage) {
      await router.push({ name: 'subtitles' })
    }
  } catch (error) {
    await store.dispatch(AUTH_LOGOUT)
    await router.replace({ name: 'login' })
  }
}

;(async () => {
  await init()

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})()
