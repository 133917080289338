import Vue from 'vue'
import Vuex from 'vuex'
import auth, { AUTH_ROLE_ADMIN } from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      username: '',
      role: '',
      refreshToken: ''
    }
  },
  getters: {
    isAuthenticated: state => !!state.auth.token,
    username: state => state.auth.username,
    isAdmin: state => state.auth.role == AUTH_ROLE_ADMIN,
    role: state => {
      return state.auth.role
    },
    token: state => state.auth.token
  },
  mutations: {},
  actions: {},
  modules: {
    namespaced: true,
    auth
  }
})
