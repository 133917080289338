<template>
  <el-container id="app">
    <el-header>
      <navigation />
    </el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'App',
  components: {
    Navigation
  }
}
</script>

<style lang="scss">
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
html,
body,
html * {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  margin: 0;
}

.el-main {
  padding-top: 10px !important;
  background-color: #f5f5f5;
}

.el-header {
  padding: 0 !important;
}
</style>
